md-toast .md-toast-content {
        color: #fff !important;
    }




md-toast.md-fp-simple-toast-success-theme .md-toast-content {
            background-color: #4caf50 !important;
        }




md-toast.md-fp-simple-toast-warn-theme .md-toast-content {
            color: #333 !important;
            background-color: #ffeb3b !important;
        }




md-toast.md-fp-simple-toast-error-theme .md-toast-content {
            background-color: #f44336 !important;
        }
md-icon.size-16 {
        width: 16px !important;
        height: 16px !important;
    }
md-icon.size-24 {
        width: 24px !important;
        height: 24px !important;
    }
md-icon.size-32 {
        width: 32px !important;
        height: 32px !important;
    }
md-icon.size-36 {
        width: 36px !important;
        height: 36px !important;
    }
md-icon.size-64 {
        width: 64px !important;
        height: 64px !important;
    }
md-card md-card-actions.layout-xs-column .md-button, md-card md-card-actions.layout-sm-column .md-button, md-card md-card-actions.layout-lg-column .md-button, md-card md-card-actions.layout-gt-xs-column .md-button, md-card md-card-actions.layout-gt-sm-column .md-button {
                margin-left: 0 !important;
                margin-left: initial !important;
                margin-right: 0 !important;
                margin-right: initial !important;
                margin: 2px 0 !important;
            }
md-tabs[fp-card-flow][hide-tab-bar] md-tabs-wrapper {
            display: none;
        }


.fpop-media-layout-gt-xs .md-subheader.larger-text {
        font-size: 16px;
    }




/*
    General Cards
    We use cards on many sites
*/
@media screen and (max-width: 768px) {
md-card.fp-card {
        margin-right: 4px;
        margin-left: 4px

}
    }
/* Angular Material - Mobile Helper */
@media screen and (max-width: 768px) {
        md-card.fp-card.mobile-padding md-card-content {
                padding: 8px
        }
            }
@media screen and (max-width: 768px) {

        md-card.fp-card.mobile-padding md-card-title {
                padding: 16px 12px 12px
        }
            }
md-card.fp-card {

}
md-card.fp-card md-card-title-text .md-headline {
            margin-top: 0px;
        }
md-card.fp-card md-card-title-text .md-title {
            margin: 0px;
        }
md-card.fp-card md-card-title.low-profile {
            padding: 16px;
        }
md-card.fp-card md-card-title.low-profile h1,md-card.fp-card md-card-title.low-profile h2,md-card.fp-card md-card-title.low-profile h3,md-card.fp-card md-card-title.low-profile h4,md-card.fp-card md-card-title.low-profile h5,md-card.fp-card md-card-title.low-profile h6 {
                margin: 0;
                padding: 0;
            }
md-card.fp-card md-card-title md-icon.fp-large {
            width: 48px;
            height: 48px;
        }
md-card.fp-card md-card-content.pull-up, md-card.fp-card div.pull-up {
            margin-top: -70px;
        }
md-card.fp-card.fixed-height-320 {
        height: 320px;
        min-height: 320px;
        max-height: 320px;
    }



/*
    Status Indicator
*/
md-dialog.fpop-status-indicator {
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    position: relative;
}
md-dialog.fpop-status-indicator md-progress-circular {
        margin: auto;
    }
md-dialog.fpop-status-indicator .progress-box {
        margin: auto;
        width: 60%;
    }
md-dialog.fpop-status-indicator .progress-box .ui.progress .bar > .progress {
            color: #555;
        }


md-dialog.num-transfer-terms {
    max-width: 600px;
}


md-list-item.fp-menu-item-selected a {
    background-color: rgba(34,166,227,0.22);
}
.md-button.md-icon-button.fp-left-sidenav-toggle,
.fp-left-sidenav-toggle {
    margin-left: -10px;
}

.fp-md-header {
    min-height: 64px;
}

.fp-md-header .menuItems .md-button.fp-nav-item-selected,
.fp-nav-item-selected {
    background-color: rgba(256,256,256,0.22);
}
md-toolbar.md-small,
    md-toolbar.md-small .md-toolbar-tools {
        height: 40px;
        min-height: 40px;
        max-height: 40px;
    }
sub-nav {
    z-index: 0;
}
sub-nav md-toolbar back-nav {
            margin-left: -20px;
        }




h4 + md-input-container {
    margin-top: -18px;
}


md-input-container + .ui.pointing.label {
    margin-top: -18px;
}



/* XEditable Styles & Overrides */
.editable-wrap {

}
.editable-wrap.editable-cc-exp > md-input-container {
        margin-top: 0;
    }
.editable-wrap username-change-field md-input-container, .editable-wrap password-change-field md-input-container {
            margin: 0;
        }
.editable-wrap md-input-container ng-messages {
            display: block;
        }


.editable-buttons > .md-button {
    margin: auto 0;
}

md-input-container.editable-controls [ng-message] {
    position: static;
}




.md-button.fp-small {
    min-width: 0;
    min-width: initial;
}

.md-button.fpop-menu-button {
    padding-right: 18px;
}

.md-button.fpop-menu-button:after {
    display: block;
    content: '\25BC';
    speak: none;
    font-size: 13px;
    transform: scaleY(0.5) scaleX(1) translateY(50%);
    position: absolute;
    right: 12px;
    top: 0;
}


md-input-container > fpop-help  {
    position: absolute;
    top: 8px;
    right: auto;
}



/*
    Carousel (md-tab / fp-card-flow)
*/

md-tabs[ fp-card-flow ] {
    position: relative;
}

md-tabs[ fp-card-flow ] .fp-navdot {
        position: absolute;
        bottom: 2%;
        right: auto;
        left: 50%;
        margin: auto;
        transform: translateX( -50% ) scale( 1 );
    }
.fpop-media-layout-sm .fp-navdot.fp-navdot-scaleup,
.fpop-media-layout-xs .fp-navdot.fp-navdot-scaleup {

    bottom: 1%;
    transform: translateX( -50% ) scale( 0.6 );
}
.fpop-media-layout-sm .fp-navdot.fp-navdot-scaleup li a, .fpop-media-layout-xs .fp-navdot.fp-navdot-scaleup li a {
        border: 1px solid rgba(0, 0, 0, .6);
    }
.fp-card-flow-next md-icon, .fp-card-flow-prev md-icon {
        fill: #777;
    }
.fp-card-flow-next,
.fp-card-flow-prev {

    position: absolute;
    top: 50%;
    transform: translateY( -50% );
}

.fp-card-flow-prev {
    left: 1%;
}

.fp-card-flow-next {
    right: 1%;
}



/*
    Corner Alert
    TODO: Needs to be able to do both left & right placements
*/
fpop-corner-alert {
    outline: none;
}
fpop-corner-alert .corner.label {
        outline: none;
    }
fpop-corner-alert .corner.label md-icon {
            position: absolute;
            top: 8px;
    		fill: #fff;
    		width: 16px;
    		height: 16px;
    	}
fpop-corner-alert .corner.label md-icon svg {

    			width: 16px;
    			height: 16px;
    		}
fpop-corner-alert .corner.label.left md-icon {
                left: 8px;
            }
fpop-corner-alert .corner.label.right md-icon {
                right: 0;
            }

fpop-corner-alert .pointing.label, .fpop-corner-alert .pointing.label {

        white-space: nowrap;
        opacity: 0.92
    }

fpop-corner-alert .pointing.label.right:before, fpop-corner-alert .pointing.label.left:before, .fpop-corner-alert .pointing.label.right:before, .fpop-corner-alert .pointing.label.left:before {
                top: 12px !important;
            }




/*
    MD LIST with Flag icons (for country)
*/

.fp-country-list {

}
.fp-country-list .fp-flag-icon, .fp-country-list-items .fp-flag-icon {
        display: inline-block;
        vertical-align: sub;
        border-radius: 3px;
        margin-right: 0.4em;
    }











/*# sourceMappingURL=fpop-md.css.map */
